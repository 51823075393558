import React from 'react';

import SEO from '../components/content/seo';
import Layout from '../containers/layout';

const seo = {
  title: 'Events',
  description: 'LiveMenu events.',
  keywords: ['events'],
};

const EventsPage = ({ pageContext }) => {
  console.log('pageContext', pageContext.slug);
  return (
    <Layout>
      <SEO
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
      />
    </Layout>
  );
};
export default EventsPage;
